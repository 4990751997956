import React, { useState, useEffect } from "react";
import seo from "../lib/seo";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { AvatarIcon, FloorPlansIcon, SettingIcon } from "../components/svg";
import { FloorPlansLayout } from "../components/FloorPlansLayout";
import { SettingsLayout } from "../components/SettingsLayout";
import { getSession } from "../features/getSession";
import { Auth } from "aws-amplify";

export const Profile = () => {
  const isLoggedIn = getSession();
  const [view, setView] = useState('floorplans');
  const [user, setUser] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log("CURRENT USER: ", user);

  const getCurrentUser = async () => {
    try {
      if (!user) {
        const user = await Auth.currentUserInfo();
        setUser(user);
      }
    } catch (error) {}
  };

  const signOut = async () => {
    if (isLoggedIn) {
      try {
        await Auth.signOut();
        navigate("/");
      } catch (error) {}
    }
  };

  const isView = (val) => {
    return val === view
  }

  useEffect(() => {
    console.log(location)
    let tab = location?.state?.tab
    if ( tab ) setView(tab)
  }, [location])

  //const toggleSettings = () => setFloorPlans(!isFloorPlans);

  getCurrentUser();

  return (
    <div className="bg-blue-dark">
      {seo({ title: 'Profile' })}
      <Header />
      <div className="container mx-auto text-light px-[20px] pt-[56px] max-w-[860px]">
        <span className="text-blue-light text-[30px] md:text-[36px] font-bold">
          Hello, {user?.attributes?.["custom:first_name"]}
        </span>
        <div className="flex items-center justify-start md:justify-end pt-[25px] md:pt-0">
          {isView('floorplans') && (
            <div
              className="flex items-center justify-center hover: cursor-pointer"
              onClick={() => setView('settings')}
            >
              <div className="flex items-center justify-center w-[21px] h-[21px] rounded-[50%] overflow-hidden">
                <SettingIcon fill="#fff" />
              </div>{" "}
              <span className="ml-[9px]">Settings</span>
            </div>
          )}
          {isView('settings') && (
            <div
              className="flex items-center justify-center hover: cursor-pointer"
              onClick={() => setView('floorplans')}
            >
              <div className="flex items-center justify-center w-[21px] h-[21px]">
                <FloorPlansIcon fill="#fff" />
              </div>{" "}
              <span className="ml-[9px]">Floorplans</span>
            </div>
          )}
          <div
            className="flex items-center justify-center ml-[29px] hover: cursor-pointer"
            onClick={signOut}
          >
            <div className="flex items-center justify-center w-[21px] h-[21px] rounded-[50%] border-[2px] overflow-hidden">
              <AvatarIcon stroke="#fff" strokeWidth="4" />
            </div>{" "}
            <span className="ml-[9px]">Sign out</span>
          </div>
        </div>
        <div className="flex flex-col">
          {isView('floorplans') && user ? (
            <FloorPlansLayout user={user} />
          ) : (
            <SettingsLayout />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
