import React, { useEffect, useState } from "react";
import { ButtonSelectable } from "../ButtonSelectable";
import { TextInput } from "../TextInput";
import { Toggle } from "../Toggle";
import { FileUploader } from "../FileUploader/FileUploader";
import { QuestionnaireQuestionBlock } from "./QuestionnaireQuestionBlock";
import { QuestionTitle } from "./QuestionTitle";
import { Button } from "../Button";
import { QuestionnaireStepsEnum } from "../../lib/QuestionnaireStepsEnum";


export const areaOptions = ["sqm", "sqft"];

export const OfficeStep = ({ setStep, setData }) => {
  const [activeAreaIndex, setActiveAreaIndex] = useState(0);
  const [internalAreaValue, setInternalAreaValue] = useState("");
  //const [desksValue, setDesksValue] = useState();
  const [peopleValue, setPeopleValue] = useState(0);
  const [file, setFile] = useState(null);
  const [cadFile, setCADFile] = useState(null);

  const [maxAllowedOccupancySelected, setMaxAllowedOccupancySelected] = useState("");

  const handleSelect = (o) => {
    const activeIndex = areaOptions.findIndex((option) => option === o);
    setActiveAreaIndex(activeIndex);
  };

  const isNextBtnDisabled = !internalAreaValue || !file

  const handleNext = () => {
    if (isNextBtnDisabled) return;

    setData({
      file,
      cadFile,
      //desksValue,
      peopleValue,
      internalAreaValue,
      activeAreaIndex,
    });
    setStep(QuestionnaireStepsEnum.REQUIREMENTS);
  };


  return (
    <div>
      <QuestionnaireQuestionBlock>
        <QuestionTitle hintText="Net internal area (NIA) is a measure of the useable space of a floor measured to the internal face of perimeter or external walls.">
          What is the net internal area of the office?
        </QuestionTitle>

        <div className="mb-4">
          <Toggle
            options={areaOptions}
            handleSelect={handleSelect}
            activeIndex={activeAreaIndex}
          />
        </div>
        <TextInput
          placeholder="Type net internal area"
          value={internalAreaValue}
          onChange={({ target }) => setInternalAreaValue(target.value)}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
          rightElement={internalAreaValue && areaOptions[activeAreaIndex]}
          type="number"
          min="0"
        />
      </QuestionnaireQuestionBlock>

      <QuestionnaireQuestionBlock>
        <QuestionTitle hintText="Maximum allowed occupancy is the number of people permitted by law to occupy a particular space at any given time. <br>It is based on fire regulations, supply of fresh air and sanitary provision.">
          Do you know the maximum allowed occupancy?
        </QuestionTitle>

        <div className="flex gap-2 mb-3 max-w-[345px]">
          <ButtonSelectable
            isSelected={maxAllowedOccupancySelected === "Yes"}
            handleClick={() => setMaxAllowedOccupancySelected("Yes")}
          >
            Yes
          </ButtonSelectable>
          <ButtonSelectable
            isSelected={maxAllowedOccupancySelected === "No"}
            handleClick={() => setMaxAllowedOccupancySelected("No")}
          >
            No
          </ButtonSelectable>
        </div>

        {maxAllowedOccupancySelected === "Yes" && (
          <TextInput
            placeholder="Type net internal area"
            value={peopleValue}
            onChange={({ target }) => setPeopleValue(target.value)}
            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
            rightElement={internalAreaValue && "people"}
            type="number"
          />
        )}
      </QuestionnaireQuestionBlock>

      <QuestionnaireQuestionBlock>
        <h5 className="font-bold mb-1 text-[22px] md:text-6 leading-tight">
          Upload your floorplan
        </h5>
        <p className="mb-2">Please upload a PDF copy of your floorplan
        <br/>If you want your floorplan to be converted into CAD, please add a DWG file</p>
        <div className="flex gap-6 mt-8">
          <FileUploader {...{ file, setFile }} />
          <FileUploader {...{ 
            file: cadFile, 
            setFile: setCADFile,
            allowedTypes: ['DWG'],
            allowedMimeTypes: ['application/acad', 'application/x-acad', 'application/autocad_dwg', 'image/x-dwg', 'application/dwg', 'application/x-dwg', 'application/x-autocad', 'image/vnd.dwg', 'drawing/dwg']
          }} />
        </div>
      </QuestionnaireQuestionBlock>

      <div>
        <Button className="mr-4 text-[1rem] py-1" bgColor="#fff">
          Back
        </Button>
        <Button
          className=" text-[1rem] py-1"
          bgColor={isNextBtnDisabled ? "#79809A" : "#8DAEFF"}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
