// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Plan, Summarys, Requirements, Item } = initSchema(schema);

export {
  Plan,
  Summarys,
  Requirements,
  Item
};