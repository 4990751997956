import React from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { TermsAndConditions } from "../components/TermsAndConditions";

export const Terms = () => {
  return (
    <>
      {seo({ title: 'Terms' })}
      <Header />
      <TermsAndConditions />
      <Footer />
    </>
  );
};
