import React from "react";
import seo from "../lib/seo";

import { Footer } from "../components/commonSections/Footer";

import { Hero } from "../components/commonSections/HeroWithVideo";
import { CuttingEdge } from "../components/waitlistSections/CuttingEdge";
//import { TeamSection } from "../components/waitlistSections/Team";
//import { EarlyAccess } from "../components/waitlistSections/EarlyAccess";

import { Steps } from "../components/commonSections/Steps";
import { Info } from "../components/commonSections/Info";
import { CallToAction } from "../components/commonSections/CallToAction";

export const Home = () => (
  <>
    {seo({ title: 'Home' })}
    <Hero />
    <CuttingEdge />
    <Steps />
    <Info />
    <CallToAction />
    <Footer />
  </>
);
