import React, { useState } from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { TextInput } from "../components/TextInput";
import { TextArea } from "../components/TextArea";
import { SubmitButton } from "../components/SubmitButton";

export const GetInTouch = () => {

  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");


  const submitForm = async(event) => {
    
    event.preventDefault()
    let response = await fetch(
      "https://api.neuralspacedesign.com/contact",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        body: JSON.stringify({
          formName: formName,
          formEmail: formEmail,
          formMessage: formMessage,
        }),
      }
    )

    if ( response.status === 422 ) {
      console.log("hello")
      let res = await response.json()
      setErrors(res)
      setSubmitted(false)
    } else if ( response.status === 200 ) {
      setSubmitted(true)
    }
  

  }

  return (
    <>
      {seo({ title: 'Get in touch' })}
      <Header />
      <div className="site-content bg-blue-light py-[32px]">
        <div className="container mx-auto">
          <div className="md:w-[456px]">

            {submitted ? (
              <>
                <h2 className="text-[32px] font-bold leading-10 text-blue-dark md:text-[48px] md:leading-[80px]">
                  Thank you
                </h2>
                <div className="mt-[25px] mb-[30px] md:mt-[20px] text-[18px] font-normal leading-[1.5] md:text-[24px]">
                  <span>We'll be in touch shortly with a response.</span>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-[32px] font-bold leading-10 text-blue-dark md:text-[48px] md:leading-[80px]">
                  Get in touch
                </h2>
                <div className="mt-[25px] mb-[30px] md:mt-[20px] text-[18px] font-normal leading-[1.5] md:text-[24px]">
                  <span>Contact us to get your queries and questions answered.</span>
                </div>

                <form onSubmit={(event) => submitForm(event)} className="text-[1rem] text-light">
                    {errors.length ? (
                      <div className="bg-red-100 mb-[15px] border border-light text-light bg-blue-dark px-4 py-3 rounded relative" role="alert">
                          <ul>
                              {errors.map((error, index) => (
                                  error.conditions.map((error, index) => (
                                      <li key={index}>
                                          {error.message}
                                      </li>
                                  ))
                              ))}
                          </ul>
                      </div>
                  ): <></>}

                  <div className="mb-[10px]">
                    <TextInput
                      type="text"
                      placeholder="Full name"
                      onChange={(event) => {
                        setFormName(event.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-[10px]">
                    <TextInput
                      type="text"
                      placeholder="Email"
                      onChange={(event) => {
                        setFormEmail(event.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-[10px]">
                    <TextArea
                      placeholder="Message"
                      onChange={(event) => {
                        setFormMessage(event.target.value);
                      }}
                    />  
                  </div>

                  <SubmitButton text="Submit" />
                </form>
              </>
            )}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
