import React, { useState } from "react";
import seo from "../lib/seo";
import { Input } from "../components/Input";
import { NextButton } from "../components/NextButton";
import { useDispatch } from "react-redux/es/exports";
import { setUserPassword, setIsAuth } from "../features/user/userSlice";
import { Button } from "../components/Button";
import { ErrorMessage } from "../components/ErrorMessage";
import { TextInput } from "../components/TextInput";
import { Header } from "../components/commonSections/Header";
import { Footer } from "../components/commonSections/Footer";

import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "@aws-amplify/ui-react";


export const ForgotPassword = () => {
  const user = useSelector((state) => state.user.user);
  const [isVisibleLoader, setVisibleLoader] = useState(true);
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [isSendedForgotPassword, setSendedForgotPassword] = useState(false);
  const [code, setCode] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPassword = async () => {
    if (email) {
      setVisibleLoader(true);
      await Auth.forgotPassword(email)
        .then((data) => {
          setVisibleLoader(false);
          setSendedForgotPassword(true);
          setError("");
        })
        .catch((err) => {
          setVisibleLoader(false);
          setError(err);
        });
    }
  };

  const submitForgotPassword = async () => {
    if (email && code && updatedPassword) {
      setVisibleLoader(true);
      await Auth.forgotPasswordSubmit(email, code, updatedPassword)
        .then((data) => {
          setVisibleLoader(false);
          navigate('/login')
          setSendedForgotPassword(false);
          setError("");
        })
        .catch((err) => {
          setVisibleLoader(false);
          // setSendedForgotPassword(false);
          setError(err);
        });
    }
  };

  return (
    <div className="relative bg-blue-dark">
      {seo({ title: 'Create Password' })}
      <Header />
      <div className="container mx-auto text-light h-[80vh] pt-[54px] pb-[15px]">
        <div className="flex flex-col items-start justify-center">
          {isSendedForgotPassword ? (
            <>
              <h1 className="font-bold text-blue-light md:text-[2rem] text-[1.875rem] mb-5 md:mb-3">
                Update your password
              </h1>
              <div className="m-0 mb-8 text-[1rem] md:text-[1.25rem]">Please enter the code sent to your email</div>
              <div className="relative w-full max-w-[345px]">
                <div className="mb-[10px]">
                  <TextInput
                    type="text"
                    value={code}
                    placeholder="Code"
                    onChange={(event) => setCode(event.target.value)}
                  />
                </div>
                <div className="mb-[24px]">
                  <TextInput
                    type="text"
                    value={updatedPassword}
                    placeholder="New password"
                    onChange={(event) =>
                      setUpdatedPassword(event.target.value)
                    }
                  />
                </div>
                <Button
                  onClick={() => submitForgotPassword()}
                  className="w-fit"
                >
                  Submit update
                </Button>
              </div>
            </>
          ) : (
            <>
              <h1 className="font-bold text-blue-light md:text-[2rem] text-[1.875rem] mb-5 md:mb-3">
                Forgot your password?
              </h1>
              <div className="m-0 mb-8 text-[1rem] md:text-[1.25rem]">Please enter your email address</div>
              <div className="relative w-full max-w-[345px]">
                <div className="mb-[24px]">
                  <TextInput
                      type="text"
                      value={email}
                      placeholder="Email"
                      onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <Button onClick={() => forgotPassword()} className="w-fit">
                    Send code
                </Button>
              </div>
            </>
          )}
        </div>
        {error && <ErrorMessage error={error} />}
      </div>
      <Footer />
    </div>
  );
};
