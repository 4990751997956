import React from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";

export const FAQs = () => {

  const faqs = [
    { question: 'How do I upload a plan?', answer: 'You will be prompted to upload your plan while you fill out the requirement form. The requirement form can be started by clicking the Get Started button on the home page.' },
    { question: 'How many floorplans can I upload per order?', answer: 'You can upload only one floorplan per order' },
    { question: 'What file type would the product come as?', answer: 'In the initial release, the floorplan will be returned as a PNG file type.' },
    { question: 'What file type does the floorplan that needs uploading have to be?', answer: 'You will need to upload a PDF and DWG file type.' },
    { question: 'How many layout options will I receive?', answer: 'You will be given 3 layouts of the 1 plan' },
    { question: 'What is the refund policy?', answer: 'Once you download the product, you won\'t be able to receive a refund. If there is an issue with your product, please contact the support team on support@neuralspacedesign.com' },
    { question: 'How long will it take for me to receive my order?', answer: 'You shall receive your order within 24 hours' },
    { question: 'Where is my confirmation code or order summary?', answer: 'Please wait a couple of minutes and then check your junk folder in your email inbox. If you still have not received the email code or confirmation, get in touch with the customer support team through the chatbot or via email at support@neuralspacedesign.com' },
    { question: 'I have issues with logging in or want to delete my account', answer: 'please contact the support team on support@neuralspacedesign.com and to delete your account, click on the delete account button.' },
  ]

  return (
    <>
      {seo({ title: 'FAQs' })}
      <Header />
      
      <div className="container mx-auto py-12">
        <div className="faqs">
          {faqs.map(item => (
            <div className="item mb-6">
              <div className="item-header font-bold">{item.question}</div>
              <div className="item-body">{item.answer}</div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};
