import React, { useState, useEffect, useRef } from "react";
import { LoginLayout } from "../LoginLayout";
import { SignUpLayout } from "../SignUpLayout";
import { uid } from "../../features/uid";
import { DataStore } from "@aws-amplify/datastore";
import { Plan } from "../../models";
import { uploadToAWS } from "../FileUploader/FileUploader";
import sendEmail from "../../features/sendEmail";
import { Hub } from 'aws-amplify';
import { getSession } from '../../features/getSession'
import Moment from "moment";

export const SubStepEnum = {
  LOGIN: "login",
  REGISTRATION: "registration",
};

const subSteps = [
  { id: SubStepEnum.LOGIN, component: LoginLayout },
  { id: SubStepEnum.REGISTRATION, component: SignUpLayout },
];

export const CompleteStep = ({ data, setStep, setData }) => {
  const [selectedSubStep, setSelectedSubStep] = useState(subSteps[0]);
  const hasCreatedPlan = useRef(false)


  const savePlan = async(user, data) => {
    const ID = uid();
    let floorPlans = data?.selectedWorkplace || "" 

    try {
      var pdfFile = await uploadToAWS(data?.file, { pathPrefix: ID });
      console.log(pdfFile)
      if ( data.cadFile ) {
        var cadFile = await uploadToAWS(data?.cadFile, { pathPrefix: ID });
      }
    } catch(e) {
      console.log("error uploading one of the files")
    }

    const plan = {
      planId: ID,
      owner: user?.username,
      title: floorPlans.title || "",
      image: floorPlans.imageSrc || "",
      pdfPath: pdfFile ? pdfFile.key : "",
      cadPath: cadFile ? cadFile.key : "",
      createdAt: Moment().format("DD/MM/YYYY"),
      subTotal: data.subTotal,
      vat: data.vat,
      total: data.total,
      summary: data.summary || "",
      activeAreaIndex: Number(data.activeAreaIndex),
      internalAreaValue: Number(data.netInternalArea),
      desksValue: Number(data.desksValue),
      peopleValue: Number(data.peopleValue),
      summaryArray: data.summaryList,
      requirementsArray: data.requirementsList,
    }

    console.log("plan data");
    console.log(plan);

    console.log("owner");
    console.log(user);

    await DataStore.save(
      new Plan(plan)
    )

    await fetch(
      "https://api.neuralspacedesign.com/order",
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          plan: {
            ...plan, 
          },
          user: {
            name: `${user.attributes['custom:first_name']} ${user.attributes['custom:surname']}`,
            email: user.attributes['email'],
          }
        }),
      }
    )

  }


  const checkAndSave = async() => {
    let user = await getSession()

    if ( user ) {
      console.log("Saving plan (from non-auth event) ...")
      savePlan(user, data)
    } else {
      console.log("Running code that attaches auth event ...")
      Hub.listen('auth', ({ payload }) => {
          const { event } = payload;
          if ( ! hasCreatedPlan.current && ( event === 'autoSignIn' || event === 'signIn' ) ) {
            console.log("Auto sign-in complete")
            let user = payload.data;
            console.log("Saving plan (from auth event) ...")
            savePlan(user, data)
            hasCreatedPlan.current = true
          }
      })
    }
  }

  useEffect(() => {
    checkAndSave()
  }, [])


  //console.log("complete data: ", data);

  const handleSubStepSelect = (id) => {
    setSelectedSubStep(subSteps.find((s) => s.id === id));
  };

  const SubStepComponent = selectedSubStep?.component;

  if (SubStepComponent) {
    return (
      <SubStepComponent
        {...{
          data,
          setStep,
          setData,
          noPadding: true,
          questionnarie: true,
          onChangeStep: handleSubStepSelect,
          setSelectedSubStep,
        }}
      />
    );
  }

  return (
    <SubStepComponent
      {...{
        data,
        setStep,
        setData,
        noPadding: true,
        questionnarie: true,
        onChangeStep: handleSubStepSelect,
        setSelectedSubStep,
      }}
    />
  );
};
