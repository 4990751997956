import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getSession } from "../../features/getSession";

export default function PrivateRoute({ redirectTo = "/login" }) {

    const location = useLocation();
    const [isLoggedIn, setLoggedIn] = useState(null)
    
    useEffect(() => {
        getSession()
        .then((res) => setLoggedIn(res))
        .catch((res) => setLoggedIn(res))
    }, [])

    if ( isLoggedIn === null ) {
        return null // or loading indicator/spinner/etc
    }

    return isLoggedIn ? <Outlet /> : <Navigate to={redirectTo} replace state={{ from: location }} />;
}
