import React, { useState } from "react";
import { QuestionnaireStepsEnum } from "../../lib/QuestionnaireStepsEnum";
import { Button } from "../Button";
import { ButtonSelectable } from "../ButtonSelectable";
import { QuestionnaireQuestionBlock } from "./QuestionnaireQuestionBlock";
import { QuestionTitle } from "./QuestionTitle";
import { SelectRequirements } from "./SelectRequirements";
import { SelectTypeOfWorkSpace } from "./SelectTypeOfWorkSpace";

export const SubStepEnum = {
  AUTO_GENERATE: "autogenerate",
  MANUALLY_GENERATE: "manuallyGenerate",
};

const subSteps = [
  { id: SubStepEnum.AUTO_GENERATE, component: SelectTypeOfWorkSpace },
  { id: SubStepEnum.MANUALLY_GENERATE, component: SelectRequirements },
];

export const RequirementsStep = ({ data, setStep, setData }) => {
  //const [selectedSubStep, setSelectedSubStep] = useState(null);
  const [selectedSubStep, setSelectedSubStep] = useState( subSteps[1] );

  const handleSubStepSelect = (id) =>
    setSelectedSubStep(subSteps.find((s) => s.id === id));

  const SubStepComponent = selectedSubStep?.component;

  if (SubStepComponent)
    return (
      <SubStepComponent {...{ data, setStep, setData, setSelectedSubStep }} />
    );

  return (
    <div>
      <QuestionnaireQuestionBlock>
        <QuestionTitle className="max-w-[530px]">
          Would you like an autogenerated layout based on the information you
          have provided or would you like to select every space yourself?
        </QuestionTitle>

        <div className="max-w-[345px]">
          <ButtonSelectable
            className="mb-2 text-[1rem]"
            handleClick={() => handleSubStepSelect(SubStepEnum.AUTO_GENERATE)}
          >
            Autogenerated layout
          </ButtonSelectable>
          <ButtonSelectable
            className="text-[1rem]"
            handleClick={() =>
              handleSubStepSelect(SubStepEnum.MANUALLY_GENERATE)
            }
          >
            I'll select the spaces myself
          </ButtonSelectable>
        </div>
      </QuestionnaireQuestionBlock>

      <Button
        className="mr-4 text-[1rem] py-1"
        bgColor="#fff"
        onClick={() => setStep(QuestionnaireStepsEnum.OFFICE)}
      >
        Back
      </Button>
    </div>
  );
};
