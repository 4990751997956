import React from "react";
import seo from "../lib/seo";
import { Header } from "../components/commonSections/Header";
import { Footer } from "../components/commonSections/Footer";
import { SignUpLayout } from "../components/SignUpLayout";

export const SignUp = () => {
  return (
    <>
      {seo({ title: 'Signup' })}
      <Header />
      <SignUpLayout />
      <Footer />
    </>
  );
};
