import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../commonSections/Header";
import { Button } from "../Button";
import WorkPlaceDesign from "../../assets/workplace-design.jpeg";
import { Link } from "../Link";

export const Hero = () => {
  const navigate = useNavigate();
  const video = useRef(null);
  const [muted, setMuted] = useState(true)

  const toggleMute = () => {
    setMuted( !muted )
  }

  useEffect(() => {
    video.current.muted = muted
  }, [muted])

  return (
    <div className="bg-blue-light text-blue-dark">
      <Header />

      <div className="container mx-auto pt-10 md:pt-20 pb-24 md:pb-28">

        <div className="w-[900px] max-w-[100%] mx-auto">
          <h1 className="m-0 mb-16 text-[2.25rem] lg:text-[2.75rem] font-bold text-center leading-[1.3]">
              Artificial intelligence that revolutionises workplace design
          </h1>

          <div className="mb-16">
            <div className="video-container relative">
              <video playsInline autoPlay muted loop ref={video}>
                <source type="video/mp4" src="https://neural-design-videos.s3.amazonaws.com/neural-hero-video.mp4"></source>
              </video>
              <button onClick={toggleMute} className="bg-light border border-[#ddd] font-bold py-2 px-4 rounded absolute top-[20px] right-[20px]">
                <svg xmlns="http://www.w3.org/2000/svg" className="inline-block mt-[-4px] mr-[4px]" version="1.0" width="20" height="20" viewBox="0 0 75 75" stroke="#1F2C56" strokeWidth="5">
                    <path d="m39,14-17,15H6V48H22l17,15z" fill="#1F2C56" strokeLinejoin="round"></path>
                    <path d="m49,26 20,24m0-24-20,24" fill="none" strokeLinecap="round"></path>
                  </svg>
                  <span>{muted ? 'Unmute' : 'Mute'}</span>
                </button>
            </div>
          </div>

          <p className="w-[750px] max-w-[100%] mx-auto mb-4 md:mb-8 text-[1.25rem] md:text-[1.35rem] text-center">
              We have built an AI driven generative design platform that will create floorplans based on your specific requirements.
          </p>

          <div className="flex justify-center">
            <Link to="/questionnaire">
              Start creating
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
