import React, { useState } from "react";
import seo from "../lib/seo";
import { RangeSlider } from "../components/RangeSlider";
// import planImg from "./../assets/Plan.png";

import agile from "./../assets/agile.png";
import traditional from "./../assets/traditional.png";
import hybrid from "./../assets/hybrid.png";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/commonSections/Header";

export const AutoGenerateDesign = () => {
  const [plan, setPlan] = useState({ value: 1 });
  const navigate = useNavigate();
  const planImg =
    plan.value === 0 ? traditional : plan.value === 1 ? hybrid : agile;
  return (
    <>
      {seo({ title: 'Auto-generated Design' })}
      <Header />
      <div className="px-[20px] sm:px-[45px] text-white pt-[40px] sm:pt-[70px] pb-[115px]">
        <div className="text-[#77F2A1] text-[38px] sm:text-[44px] md:text-[46px] lg:text-[54px]  xl:text-[80px] font-bold">
          Auto-generated design
        </div>
        <div className="text-[28px]  sm:text-[34px]  lg:text-[38px]  xl:text-[40px]  mb-[20px]  font-bold ">
          What style of workplace would you like?
        </div>
        <img src={planImg} alt="img" className="ml-auto mr-auto mb-[75px]" />
        <div className="max-w-[490px] ml-auto mr-auto mb-[40px]">
          <RangeSlider
            value={plan}
            setValue={setPlan}
            marks={["Traditional", "Hybrid", "Agile"]}
            min={0}
            max={2}
          />
        </div>
        <div className="max-w-[260px] ml-auto mr-auto mb-[95px]">
          <div className="mb-[10px] relative  before:content-'' before:absolute before:rounded-full before:block  before:left-[-20px] before:top-[8px] before:w-[8px] before:h-[8px] before:bg-white ">
            Share work stations
          </div>
          <div className="mb-[10px] relative  before:content-'' before:absolute before:rounded-full before:block  before:left-[-20px] before:top-[8px] before:w-[8px] before:h-[8px] before:bg-white ">
            More open collaboration spaces
          </div>
          <div className="mb-[10px] relative  before:content-'' before:absolute before:rounded-full before:block  before:left-[-20px] before:top-[8px] before:w-[8px] before:h-[8px] before:bg-white ">
            More social spaces
          </div>
          <div className="mb-[10px] relative  before:content-'' before:absolute before:rounded-full before:block  before:left-[-20px] before:top-[8px] before:w-[8px] before:h-[8px] before:bg-white ">
            Wider range of meeting rooms
          </div>
        </div>
        <div
          onClick={() => navigate("/signup")}
          className="flex ml-auto mr-auto items-center justify-center text-[22px] font-bold cursor-pointer text-[#1F2B37] my-[35px] rounded-full bg-[#77F2A1] w-[185px] h-[50px]"
        >
          Finish
        </div>
      </div>
    </>
  );
};
