import React, { useRef } from "react";
import FloorPlanExaple from "../../assets/floor-plan-example.png";
import DesignToolExample from "../../assets/design-tool-example.png";

export const CuttingEdge = () => {

  return (
    <div className="bg-grey-light">
      <div className="container mx-auto pt-14 md:pt-28 pb-20 md:pb-28 text-blue-dark">

          <div className="w-[900px] max-w-[100%] mx-auto">
          <h2 className="m-0 mb-2 text-[2.25rem] lg:text-[2.75rem] font-bold text-center lg:leading-[1.3]">
            Hundreds of layouts produced in a matter of hours, not days
          </h2>
          {/**<p className="mx-auto md:max-w-[660px] mb-12 md:mb-12 text-[1.25rem] md:text-[1.25rem] text-center">
            Get flow in a tool that is focused on buildable exploration. Move freely between massing, setting programs, story design and space planning. Compare variants in seconds. Automatically fill stories with plans
          </p>*/}
          
          <div className="mb-20">
            <video autoPlay playsInline loop muted>
              <source type="video/mp4" src="https://neural-design-videos.s3.us-east-1.amazonaws.com/officeplan_v2.mp4"></source>  
            </video>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-between gap-x-6">

            <div className="pl-5 md:pl-18 flex-1 relative">
              <h5 className="md:max-w-[395px] m-0 mb-1.5 text-[1.5rem] md:text-[1.75rem] font-bold">
                Share a fully developed plan with anyone through a simple to use browser link
              </h5>
            </div>

            <div className="pl-5 md:pl-6 flex-1 relative">
              <img src={FloorPlanExaple} />
            </div>

          </div>
        </div>

      </div>
    </div>
  )

}