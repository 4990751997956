import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../components/TextInput";
import { SubmitButton } from "../components/SubmitButton";
import { SingleSelect } from "../components/SingleSelect";
import { TextArea } from "./TextArea";
import { AutoComplete } from './AutoComplete'

import { Auth } from "aws-amplify";
import { VerificationCodeLayout } from "./VerificationCodeLayout";
import { Loader } from "./Loader";
import { ErrorMessage } from "./ErrorMessage";
import Moment from "moment";
import { uid } from "../features/uid";

const optionsRole = [
  { value: "Real estate transaction", label: "Real estate transaction" },
  { value: "Real estate strategy", label: "Real estate strategy" },
  { value: "Real estate design", label: "Real estate design" },
];

const optionsTool = [
  {
    value: "Determine a new floor-plan’s suitability",
    label: "Determine a new floor-plan’s suitability",
  },
  {
    value: "Choose the most sutiable building based on a common brief",
    label: "Choose the most sutiable building based on a common brief",
  },
  {
    value: "Exploring efficiencies within my existing workplace",
    label: "Exploring efficiencies within my existing workplace",
  },
  {
    value: "Exploring new layout concepts within my existing workplace",
    label: "Exploring new layout concepts within my existing workplace",
  },
  {
    value: "To help build capital budgets for future projects",
    label: "To help build capital budgets for future projects",
  },
];

async function signUp(
  {
    formEmail: username,
    formPassword: password,
    formFirstName: first_name,
    formSurname: surname,
    formCompanyName: company_name,
    /*formAddress: address,
    formAddressLine1,
    formAddressLine2,
    formCity,
    formCounty,
    formPostcode,*/
    formRole: role,
    formSnapspace: snapspace_use_for,
  },
  questionnarie,
  setVisibleLoader,
  navigate,
  setError
) {
  try {
    setVisibleLoader(true);

    await Auth.signUp({
      username,
      password,
      attributes: {
        "custom:first_name": first_name,
        "custom:surname": surname,
        "custom:company_name": company_name,
        /*"custom:address": address,
        "custom:address_line_1": formAddressLine1,
        "custom:address_line_2": formAddressLine2,
        "custom:city": formCity,
        "custom:county": formCounty,
        "custom:postcode": formPostcode,*/
        "custom:role": role,
        "custom:snapspace_use_for": snapspace_use_for,
        "custom:registration_date": Moment().format("DD/MM/YYYY"),
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      },
    })

    setVisibleLoader(false);
    if (!questionnarie) {
      navigate("/verification-code", { state: { username } });
    }
  } catch (error) {
    setVisibleLoader(false);
    setError(error);
  }
}

export const SubStepEnum = {
  VERIFICATION_CODE: "verificationCode",
};

const subSteps = [
  { id: SubStepEnum.VERIFICATION_CODE, component: VerificationCodeLayout },
];

export const SignUpLayout = ({
  data,
  setData,
  setStep,
  noPadding = false,
  questionnarie = false,
}) => {
  const [selectedSubStep, setSelectedSubStep] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(false);


  const [addressSelected, setAddressSelected] = useState(false)
  const [formAddressLine1, setFormAddressLine1] = useState("")
  const [formAddressLine2, setFormAddressLine2] = useState("")
  const [formCity, setFormCity] = useState("")
  const [formCounty, setFormCounty] = useState("")
  const [formPostcode, setFormPostcode] = useState("")

  const [formFirstName, setFormFirstName] = useState("");
  const [formSurname, setFormSurname] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formCompanyName, setFormCompanyName] = useState("");
  const [formAddress, setFormAddress] = useState("");
  const [formRole, setFormRole] = useState("");
  const [formSnapspace, setFormSnapspace] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [isVisibleLoader, setVisibleLoader] = useState(false);

  const handleSubStepSelect = (id) =>
    setSelectedSubStep(subSteps.find((s) => s.id === id));

  const onNextClick = (event) => {
    event.preventDefault();

    if (disabled) {
      return false;
    }

    signUp(
      {
        formFirstName,
        formSurname,
        formEmail,
        formCompanyName,
        /*formAddress,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formCounty,
        formPostcode,*/
        formRole,
        formSnapspace,
        formPassword,
      },
      questionnarie,
      setVisibleLoader,
      navigate,
      setError
    );
    if (questionnarie) {
      handleSubStepSelect(SubStepEnum.VERIFICATION_CODE);
    }
  };

  const disabled =
    !formFirstName ||
    !formSurname ||
    !formEmail ||
    !formCompanyName ||
    /*!formAddressLine1 ||
    !formPostcode ||*/
    !formRole ||
    !formSnapspace ||
    !formPassword;

  const SubStepComponent = selectedSubStep?.component;

  if (SubStepComponent) {
    return (
      <SubStepComponent
        {...{
          formFirstName,
          formSurname,
          file: data?.file,
          formEmail,
          summary: data?.summary,
          noPadding: true,
          questionnarie: true,
          setSelectedSubStep,
        }}
      />
    );
  }

  return (
    <>
      <div
        className={`text-light bg-blue-dark ${
          noPadding
            ? "p-0"
            : "px-[20px] sm:px-[45px] text-white   pt-[30px] sm:pt-[70px] pb-[15px]"
        }`}
      >
        <Loader visible={isVisibleLoader} />
        <div>
          <div className="text-[#C6D6FF] font-bold text-[30px] leading-7 mb-[13px] md:text-[32px] md:leading-[38px]">
            Welcome aboard
          </div>
          <div className="text-[16px] text-[#fff] font-normal left-[26px] md:text-[20px] md:leading-6 mb-[13px]">
            Please enter your details using the fields below:
          </div>
          <div className="text-[#C6D6FF] font-normal text-[14px] leading-6 mt-[45px] md:mt-[50px] mb-[7px]">
            DETAILS
          </div>
          <form className="" onSubmit={onNextClick}>
            <div className="lg:mr-[100px]">
              <div className="mb-[10px]">
                <TextInput
                  value={formFirstName}
                  placeholder="First name"
                  onChange={(event) => {
                    setFormFirstName(event.target.value);
                  }}
                />
              </div>
              <div className="mb-[10px]">
                <TextInput
                  value={formSurname}
                  placeholder="Surname"
                  onChange={(event) => {
                    setFormSurname(event.target.value);
                  }}
                />
              </div>
              <div className="mb-[10px]">
                <TextInput
                  type="email"
                  value={formEmail}
                  placeholder="Email"
                  onChange={(event) => {
                    setFormEmail(event.target.value);
                  }}
                />
              </div>
              <div className="mb-[10px]">
                <TextInput
                  value={formCompanyName}
                  placeholder="Company name"
                  onChange={(event) => {
                    setFormCompanyName(event.target.value);
                  }}
                />
              </div>


              {/*!addressSelected ? (
                <div>
                  <AutoComplete
                    onChange={(address) => {
                      setAddressSelected(true)
                      setFormAddressLine1(address.streetAddress)
                      setFormCity(address.city)
                      //setFormCounty(address.county)
                      setFormPostcode(address.postcode)
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="mb-[10px]">
                    <TextInput
                      value={formAddressLine1}
                      placeholder="Address line 1"
                      onChange={(event) => {
                        setFormAddressLine1(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-[10px]">
                    <TextInput
                      value={formAddressLine2}
                      placeholder="Address line 2 (optional)"
                      onChange={(event) => {
                        setFormAddressLine2(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-[10px]">
                    <TextInput
                      value={formCity}
                      placeholder="Town or city"
                      onChange={(event) => {
                        setFormCity(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-[10px]">
                    <TextInput
                      value={formCounty}
                      placeholder="County (optional)"
                      onChange={(event) => {
                        setFormCounty(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-[10px]">
                    <TextInput
                      value={formPostcode}
                      placeholder="Postcode"
                      onChange={(event) => {
                        setFormPostcode(event.target.value);
                      }}
                    />
                  </div>
                </>
              )}

              {!addressSelected && (
                <div className="flex w-[345px]">
                  <span
                    className={`hover:cursor-pointer text-left text-[#fff] text-[14px] font-normal leading-6 underline underline-offset-4 mt-[10px]`}
                    onClick={() => setAddressSelected(true)}
                  >
                    Enter address manually
                  </span>
                </div>
              )*/}
              <div className="mt-[44px]">
                <div className="text-base text-[#fff] font-normal leading-[20px]">
                  Which best describes your role?
                </div>
                <div>
                  <div className="mt-[11px]">
                    <SingleSelect
                      name="role"
                      options={optionsRole}
                      onChande={({ value }) => {
                        setFormRole(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[26px]">
              <div className="text-base text-[#fff] font-normal leading-[20px]">
                What would you use the NeuralSpaceDesign tool for?
              </div>
              <div>
                <div className="mt-[11px]">
                  <SingleSelect
                    name="tool"
                    options={optionsTool}
                    onChande={({ value }) => {
                      setFormSnapspace(value);
                    }}
                  />
                </div>
                <hr className="bg-[#C6D6FF] border-[#C6D6FF] mt-[37px] mb-[39px] bt-[0.5px] max-w-[345px]" />
                <div className="text-[#C6D6FF] font-normal text-[14px] leading-6 mb-[7px]">
                  ACCOUNT DETAILS
                </div>
                <div className="mb-[10px]">
                  <TextInput
                    type="email"
                    value={formEmail}
                    placeholder="Email"
                    onChange={(event) => {
                      setFormEmail(event.target.value);
                    }}
                  />
                </div>
                <div className="pb-[12px]">
                  <TextInput
                    type="password"
                    value={formPassword}
                    placeholder="Password"
                    onChange={(event) => {
                      setFormPassword(event.target.value);
                    }}
                  />
                </div>
                <ErrorMessage error={error} />
                <SubmitButton disabled={disabled} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
