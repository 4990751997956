import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from 'react'
import "./App.css";

import { AutoGenerateDesign } from "./pages/AutoGenerateDesign";
import { CreatePassword } from "./pages/CreatePassword";
import { ForgotPassword } from "./pages/ForgotPassword";
import { GetStarted } from "./pages/GetStarted";
import { PlanSubmitted } from "./pages/PlanSubmitted";
import { Registered } from "./pages/Registered";
import { SignUp } from "./pages/SignUp";
import { Login } from "./pages/Login";
import { FAQs } from "./pages/FAQs";
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy"
import { CookiePolicy } from "./pages/CookiePolicy"
import { Home } from "./pages/Home";
import { AboutUs } from "./pages/AboutUs";
import { WithScrollToTopComponent } from "./components/WithScrollToTopComponent";
import { Questionnaire } from "./pages/Questionnaire";
import { GetInTouch } from "./pages/GetInTouch";
import { VerificationCode } from "./pages/VerificationCode";
import { WaitSignUp } from "./pages/WaitSignUp";
import PrivateRoute from "./components/routs/PrivateRoute";
import PublicRoute from "./components/routs/PublicRoute";
import { Profile } from "./pages/Profile";
import { Loader } from "./components/Loader";
import { Hub } from "aws-amplify";
import { Head } from 'react'

import PrivateRoutes from './components/routes/PrivateRoute'


import { EmailPlansSubmit } from "./components/EmailsTemplate/EmailPlansSubmit";
import { Helmet } from "react-helmet";

function App() {

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if ( ( event === 'autoSignIn' || event === 'signIn' ) ) {
          console.log("Auto sign-in complete");
          const user = payload.data;
          console.log(user);
        }
    })
  }, [])
  
  return (
    <WithScrollToTopComponent>
      <Helmet>
        <script src="https://consent.cookiefirst.com/banner.js" data-cookiefirst-key="a9a9324a-de43-4fee-8007-b16761d94b28"></script>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/" element={<PrivateRoutes />} >
          <Route path="questionnaire" element={<Questionnaire />} />
        </Route>
        <Route path="/get-in-touch" element={<GetInTouch />} />
        <Route path="/signup" element={<SignUp />} />
        {/* <Route path="/signup" element={<PublicRoute redirectTo="/" />}>
          <Route index element={<SignUp />} />
        </Route> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/login" element={<PublicRoute redirectTo="/" />}>
          <Route index element={<Login />} />
        </Route> */}
        <Route path="/verification-code" element={<VerificationCode />} />
        <Route path="/profile" element={<Profile redirectTo="/" />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="/createPassword" element={<CreatePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/registered" element={<Registered />} />
        {/*<Route path="/getStarted" element={<GetStarted />} />*/}
        <Route path="/autoGenerateDesign" element={<AutoGenerateDesign />} />
        <Route path="/planSubmitted" element={<PlanSubmitted />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<CookiePolicy  />} />
      </Routes>
    </WithScrollToTopComponent>
  );
}

export default App;
