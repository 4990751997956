import { triggerEmailSend } from '../email'

export const sendEmail = async ({ 
  emailTo 
}) => {

    await fetch(
      "https://api.neuralspacedesign.com/register",
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: emailTo,
        }),
      }
    )

    /*triggerEmailSend("https://ptbpgf55l2.execute-api.us-east-1.amazonaws.com/sendSignUpConfirmation", {
        emailTo: emailTo,
    })*/

};

export default sendEmail;
