import { Helmet } from "react-helmet"

const seo = (props) => {

    let {
        title
    } = props

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )

}

export default seo