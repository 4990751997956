import React from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { LoginLayout } from "../components/LoginLayout";

export const Login = () => {
  return (
    <div className="relative bg-blue-dark">
      {seo({ title: 'Login' })}
      <Header />
      <div className="container mx-auto">
        <LoginLayout />
      </div>
      <Footer />
    </div>
  );
};
