import React from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { PrivacyPolicy } from "../components/PrivacyPolicy";

export const Privacy = () => {
  return (
    <>
      {seo({ title: 'Privacy' })}
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};
