import React, { useEffect, useState } from "react";
import cn from "classnames";
import { QuestionnaireStepsEnum } from "../../lib/QuestionnaireStepsEnum";
import { Button } from "../Button";
import { QuestionnaireQuestionBlock } from "./QuestionnaireQuestionBlock";
import { QuestionTitle } from "./QuestionTitle";
import DesksIco from "../../assets/filter-desks.svg";
import BreakOutAreaIco from "../../assets/filter-breakout-area.svg";
import ReceptionIco from "../../assets/filter-reception.svg";
import OfficeOnePersonIco from "../../assets/filter-office-one-person.svg";
import KitchenTeaPointIco from "../../assets/filter-kitchen-tea-point.svg";
import MeetingRoomSmIco from "../../assets/filter-meeting-room-sm.svg";
import MeetingRoomMdIco from "../../assets/filter-meeting-room-md.svg";
import MeetingRoomLgIco from "../../assets/filter-meeting-room-lg.svg";
import StoreRoomIco from "../../assets/filter-store-room.svg";
import TeaRoomIco from "../../assets/filter-tea-room.svg";
import PeopleIco from "../../assets/people.svg";
import MinusIco from "../../assets/minus.svg";
import PlusIco from "../../assets/plus.svg";
import { Checkbox } from "../Checkbox/Checkbox";
import CheckBoxUncheckedIco from "../../assets/checkbox-unchecked.svg";
import CheckedIco from "../../assets/checked.svg";
import { areaOptions } from "./OfficeStep";

const defaultFilters = [
  {
    id: 0,
    title: "Desks",
    quantity: 0,
    imageSrc: DesksIco,
    area: 50.5,
    area_unit: 'sqft',
    increment: 2,
    default: 2,
    min: 2,
    maxOccupancy: (value, { peopleValue }) => peopleValue && value > peopleValue,
  },
  {
    id: 1,
    title: "Office (1 person)",
    quantity: 0,
    imageSrc: OfficeOnePersonIco,
    area: 173,
    area_unit: 'sqft',
  },
  {
    id: 2,
    title: "Meeting room",
    quantity: 0,
    imageSrc: MeetingRoomSmIco,
    area: 121,
    area_unit: 'sqft',
    options: {
      icon: PeopleIco,
      option: "1-2 people",
    },
  },
  {
    id: 3,
    title: "Meeting room",
    quantity: 0,
    imageSrc: MeetingRoomSmIco,
    area: 173,
    area_unit: 'sqft',
    options: {
      icon: PeopleIco,
      option: "4-6 people",
    },
  },
  {
    id: 4,
    title: "Meeting room",
    quantity: 0,
    imageSrc: MeetingRoomMdIco,
    area: 276,
    area_unit: 'sqft',
    options: {
      icon: PeopleIco,
      option: "8-10 people",
    },
  },
  {
    id: 5,
    title: "Meeting room",
    quantity: 0,
    imageSrc: MeetingRoomLgIco,
    area: 356,
    area_unit: 'sqft',
    options: {
      icon: PeopleIco,
      option: "12-16 people",
    },
  },
  {
    id: 6,
    title: "Breakout area",
    quantity: 0,
    imageSrc: BreakOutAreaIco,
    area: 6,
    area_unit: 'percent',
    max: (value) => value > 2,
  },
  {
    id: 7,
    title: "Kitchen / Tea point",
    quantity: 0,
    imageSrc: KitchenTeaPointIco,
    area: 3.5,
    area_unit: 'percent',
    max: (value) => value > 2,
  },
  {
    id: 8,
    title: "Breakout with kitchen",
    quantity: 0,
    imageSrc: TeaRoomIco,
    area: 7.5,
    area_unit: 'percent',
    max: (value) => value > 1,
  },
  {
    id: 9,
    title: "Reception",
    quantity: 0,
    imageSrc: ReceptionIco,
    area: 300,
    area_unit: 'sqft',
    max: (value) => value > 1,
  },
];

export const SelectRequirements = ({
  data,
  setStep,
  setData,
  setSelectedSubStep,
}) => {
  const [filters, setFilters] = useState(
    defaultFilters.map((f, i) => ({
      ...f,
      quantity: f.default ? f.default : 0,
    }))
  );
  const [selectedFilters, setSelectedFilters] = useState([filters[0]]);
  const [error, setError] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isAgreeChecked, setIsAgreeChecked] = useState(false)
  useEffect(() => setShowError(!!error), [error])

  const handleNext = () => {
    if (isNextBtnDisabled) return;

    setData({
      ...data,
      choice: false,
      selectedWorkplace: 0,
      desksValue: selectedFilters.find(i => i.title === 'Desks').quantity,
      selectedFilters,
    });
    setStep(QuestionnaireStepsEnum.SUMMARY);
  };

  const getMeasurementUnit = () => {
    return areaOptions[data.activeAreaIndex]
  }

  const roundTo2DecimalPlaces = (num) => {
    return Math.round(num * 100) / 100
  }

  const areaForItem = (item) => {
    let area = 0;
    if ( item.area_unit === 'sqft' ) {
      area = item.area * item.quantity;
      if ( data.activeAreaIndex === 0 ) area = area * 0.09290304;
    } else if ( item.area_unit === 'percent' ) {
      let totalPercent = item.area * item.quantity;
      area = (totalPercent/100) * data.internalAreaValue;
    }
    return roundTo2DecimalPlaces(area)
  }

  const spaceUsed = () => {
    let arr = selectedFilters.map(item => {
      return areaForItem(item)
    })
    return roundTo2DecimalPlaces(arr.reduce((a, b) => a + b, 0))
  }

  const remainingArea = () => {
    let arr = selectedFilters.map(item => {
      return areaForItem(item)
    })
    return roundTo2DecimalPlaces(data.internalAreaValue - arr.reduce((a, b) => a + b, 0))
  }


  const canDecrementQuantity = (item, decrement) => {
    if ( (item.quantity - Math.abs(decrement)) < item.min ) {
      return false;
    }
    return true;
  }

  const canIncrementQuantity = (item, increment) => {

    if ( willExceedItemMax(item, increment ) ) {
      return false;
    }

    if ( willExceedOccupancy(item, increment) ) {
      return false
    }

    let remaining = remainingArea()
    let itemArea = areaForItem({ ...item, quantity: increment });
    if ( increment > 0 && (remaining - itemArea) < 0 ) {
      //setError({ text: `Oops! Adding more of these will take your total below zero` });
      return false;
    }

    //setError(false);
    return true;
  }

  const willExceedRemaining = (item, increment) => {
    let remaining = remainingArea()
    let itemArea = areaForItem({ ...item, quantity: increment });
    if ( increment > 0 && (remaining - itemArea) < 0 ) {
      //setError({ text: `Oops! Adding more of these will take your total below zero` });
      return true;
    }
    return false
  }
  
  const willExceedItemMax = (item, increment) => {

    if ( item.max && item.max(item.quantity + increment, data) ) {
      //setError({ text: `You have reached the maximum for this item` });
      return true;
    }

    /*if ( data.peopleValue && item.quantity > data.peopleValue ) { 
      return true;
    }*/

    return false;

  }

  const willExceedOccupancy = (item, increment) => {

    if ( item.maxOccupancy && item.maxOccupancy(item.quantity + increment, data) ) {
      return true;
    }
    
    return false;

  }

  const updateQuantity = (array, filter, coof) => {


    let itemArea = areaForItem({ ...filter, quantity: coof });
    console.log(`item area ${itemArea}`)

    // if incremements quantity, check if it will hit max for item or take total below 0
    if ( coof > 0 && ! canIncrementQuantity(filter, coof) ) {
      return array
    }

    return array.map((f) => ({
      ...f,
      quantity: f.id === filter.id ? f.quantity + coof : f.quantity,
    }));
  };

  const handleQuantityChange = (filter, coof) => {

    if ( coof < 0 && ! canDecrementQuantity(filter, coof) ) {
      return false;
    }

    if ( coof > 0 && ! canIncrementQuantity(filter, coof) ) {
      return false;
    }

    const updatedFilters = updateQuantity(filters, filter, coof);
    setFilters(updatedFilters);

    const updatedselectedFilters = updateQuantity(
      selectedFilters,
      filter,
      coof
    );
    setSelectedFilters(updatedselectedFilters);

    const isSelected = selectedFilters.some(({ id }) => id === filter.id);

    if (filter.quantity + coof > 0) {
      if (!isSelected) {
        filter.quantity += coof;
        setSelectedFilters([...selectedFilters, filter]);
      }
    } else {
      if (isSelected) {
        setSelectedFilters(
          selectedFilters.filter(({ id }) => id !== filter.id)
        );
      }
    }
  };

  const changeQuantity = (array, filter, quantity) => {
    return array.map((f) => {
      if (f.id === filter.id) {
        f.quantity = quantity;
        return f;
      }
      return f;
    });
  };

  const handleSelect = (filter) => {
    const isSelected = selectedFilters.some(({ id }) => id === filter.id);

    if (isSelected) {
      setFilters(changeQuantity(filters, filter, 0));
      setSelectedFilters(changeQuantity(selectedFilters, filter, 0));
      return setSelectedFilters(
        selectedFilters.filter(({ id }) => id !== filter.id)
      );
    }

    setSelectedFilters([...selectedFilters, filter]);
    setFilters(changeQuantity(filters, filter, filter.increment ?? 1));
  };

  const checkQuantity = () => {
    return selectedFilters.some((f) => f.quantity === 0);
  };

  const asSqft = (num) => {
    if ( data.activeAreaIndex === 0 ) {
      return num * 10.764;
    }
    return num
  }
  
  const isNextBtnDisabled = (!selectedFilters?.length && !checkQuantity()) || !isAgreeChecked;

  let notif = <></>
  let remaining = remainingArea()
  if ( asSqft(remaining) > 2000 ) notif = <span className="text-[#e1e182]">Do you know you still have plenty of space remaining?</span>
  else if ( asSqft(remaining) > 150 ) notif = <span className="text-[#e1e182]">You still have space to fill.</span>
  else notif = <span className="text-[#95e995]">Great, all your space is accounted for.</span>

  return (
    <div>
      <QuestionnaireQuestionBlock>
        <QuestionTitle>Select your requirements</QuestionTitle>

        <div onTransitionEnd={() => setShowError(false)} className={cn(showError ? 'opacity-1' : 'opacity-0', 'bg-blue-600 transition-opacity ease-in duration-700 delay-700')}>
          {error.text}
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="max-w-full md:max-w-3xl flex md:flex-wrap gap-3 overflow-y-auto noScrollBar">
            {filters.map((f) => {
              const canIncrement = canIncrementQuantity(f, f.increment ?? 1);
              const isSelected = selectedFilters.some(({ id }) => id === f.id);
              const backgroundColor = isSelected
                ? "rgba(195, 208, 255, 0.3)"
                : "#2F3C63";

              let error = <></>
              if (willExceedItemMax(f, f.increment ?? 1)) error = <>You have reached the maximum for this item</>
              else if (willExceedOccupancy(f, f.increment ?? 1)) error = <>You have reached your maximum occupancy</>
              else if (willExceedRemaining(f, f.increment ?? 1)) error = <>You don't have enough space to add any more</>

              return (
                <div
                  key={f.id}
                  className={cn('relative px-5 md:px-3 pb-6 md:pb-4 rounded-md min-w-[333px] w-[333px] md:w-[220px] md:min-w-[220px] flex flex-col justify-between items-center cursor-pointer')}
                  style={{ backgroundColor }}
                >
                  {!canIncrement && (
                    <div className="absolute w-[100%] bg-light bg-opacity-0 h-[240px] rounded-md">
                      <div className="relative w-[85%] mx-auto text-[12px] rounded-md py-4 px-6 top-[50%] translate-y-[-50%] bg-yellow font-bold text-blue-dark text-center">
                        {error}
                      </div>
                    </div>
                  )}
                  
                  <div
                    className="absolute right-3 top-3"
                    onClick={() => handleSelect(f)}
                  >
                    <img
                      src={isSelected ? CheckedIco : CheckBoxUncheckedIco}
                      alt="checked"
                    />
                  </div>

                  <div className="cursor-pointer" onClick={() => handleSelect(f)}>
                    <img
                      className="mt-8 h-[230px] md:h-[155px]"
                      src={f.imageSrc}
                      alt="filter_ico"
                    />
                  </div>

                  <div className="w-full">
                    <div className="min-h-[50px]">
                      <div className="border-b-[0.5px] pb-1 mb-3">
                        <h5 className="text-[1.5rem] md:text-[1rem] font-bold leading-8">
                          {f.title}
                        </h5>

                        {f.options && (
                          <div className="flex items-center">
                            <img src={f.options.icon} alt="option_ico" />
                            <span className="text-[11px] ml-1">
                              {f.options.option}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="text-[1.25rem] md:text-[0.875rem]">
                        Quantity
                      </div>
                      <div className="flex items-center">
                        <div
                          className="px-2 py-3 cursor-pointer"
                          onClick={() => {
                            // if (isSelected && f.quantity > 0) {
                            if (f.quantity > 0) {
                              handleQuantityChange(f, f.increment ? f.increment * -1 : -1);
                            }
                          }}
                        >
                          <img src={MinusIco} alt="minus" />
                        </div>
                        <div
                          className="px-5 py-3 text-[1.25rem] md:text-[0.875rem] rounded-md"
                          style={{
                            backgroundColor: isSelected
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          {f.quantity}
                        </div>
                        <div
                          className="px-2 py-3 cursor-pointer"
                          onClick={() => {
                            // if (isSelected) {
                            handleQuantityChange(f, f.increment ?? 1);
                            // }
                          }}
                        >
                          <img src={PlusIco} alt="plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-12 md:mt-0">
            <div className="text-[22px]"><span className="font-bold mb-6 leading-tight">Total Space Available:</span> {data.internalAreaValue} {areaOptions[data.activeAreaIndex]}</div>
            <div className="py-4">
              {selectedFilters.map((item, index) => {
                //let per = <></>
                //if ( item.area_unit === 'sqft' ) per = <>{item.area} sqft each</>
                //if ( item.area_unit === 'percent' ) per = <>{item.area}% of total</>
                return <div key={index}>X {item.quantity} - {item.title} <span className="font-1.5">({areaForItem(item)} {areaOptions[data.activeAreaIndex]})</span></div>
              })}
              <div className="mt-6"><span className="font-bold">Space Used:</span> {spaceUsed()} {areaOptions[data.activeAreaIndex]}</div>
              <div><span className="font-bold">Space remaining:</span> {remainingArea()} {areaOptions[data.activeAreaIndex]}</div>
            </div>
          </div>
        </div>
      </QuestionnaireQuestionBlock>


      <div className="font-bold mb-2">{notif}</div>

      <div className="mb-0 text-[0.875rem]">
        <Checkbox
          label={
            <span className="block max-w-[230px] md:max-w-full">
              By clicking finalise, you agree you are happy with the above selection
            </span>
          }
          checked={isAgreeChecked}
          onChange={() => {
            setIsAgreeChecked(!isAgreeChecked);
          }}
          className="flex justify-between md:justify-start items-center"
          labelClassName="md:max-w-initial"
        />
      </div>

      <div className="pt-8">
        <Button
          className="mr-4 text-[1rem] py-1"
          bgColor="#fff"
          //onClick={() => setSelectedSubStep(null)}
          onClick={() => setStep(QuestionnaireStepsEnum.OFFICE)}
        >
          Back
        </Button>
        <Button
          className=" text-[1rem] py-1"
          bgColor={isNextBtnDisabled ? "#79809A" : "#8DAEFF"}
          onClick={handleNext}
        >
          Finalise
        </Button>
      </div>
    </div>
  );
};
