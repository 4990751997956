import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoIcon from "../../assets/logo.svg";
import { Button } from "../Button";
import { BurgerIcon, CloseIcon, AvatarIcon } from "../svg";
import { slide as Menu } from "react-burger-menu";
import "./styles.css";
import { SignOutButton } from "../SignOutButton";
import { getSession } from "../../features/getSession";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link as CustomLink } from "../Link";

export const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isSessionLoaded, setSessionLoaded] = useState(false);
  


  const signOut = async () => {
    if (isLoggedIn) {
      try {
        await Auth.signOut();
        setLoggedIn(false);
        navigate("/");
      } catch (error) {}
    }
  };


  useEffect(() => {
  if (!isSessionLoaded) {
    getSession()
      .then((res) => {
        setLoggedIn(res);
        setSessionLoaded(true);
      })
      .catch((res) => {
        setLoggedIn(res);
        setSessionLoaded(true);
      });
  }

  }, [])

  const handleClose = () => setIsOpen(false);
  const navigate = useNavigate();

  // getLoggedIn();

  return (
    <>
      <header className="pt-10 pb-8 md:pt-12 md:pb-11 bg-blue-light">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <Link to="/">
              <img
                className="mb-6 md:mb-0 w-[250px] md:w-[270px]"
                src={LogoIcon}
                alt="logo"
              />
            </Link>

              {isLoggedIn ? (
                <div className="flex flex-wrap gap-4">
                  <Button className="flex-1 text-center text-[17px] whitespace-nowrap" onClick={() => navigate("/questionnaire")}>
                    Start creating
                  </Button>
                  <div
                    className="avatarIcon w-[48px] h-[48px] rounded-[50%] hover: cursor-pointer"
                    data-dropdown-toggle="dropdownNavbar"
                  >
                    <AvatarIcon />
                    <div className="pt-[10px]"></div>
                    <div className="dropdownMenu z-10 hidden font-normal bg-light divide-y divide-gray rounded shadow w-44">
                      <ul className="text-sm text-gray-700" aria-labelledby="dropdownLargeButton">
                        <li>
                          <Link to="/profile" state={{ tab: 'settings' }} className="block px-4 py-2 hover:bg-gray hover:text-light">My profile</Link>
                        </li>
                        <li>
                          <Link to="/profile" state={{ tab: 'floorplans' }} className="block px-4 py-2 hover:bg-gray hover:text-light">My orders</Link>
                        </li>
                        <li>
                          <button onClick={signOut} className="block w-full text-left px-4 py-2 hover:bg-gray hover:text-light">Sign out</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap gap-4">
                  <CustomLink to="/questionnaire" className="flex-1 text-center text-[17px] whitespace-nowrap">
                    Start creating
                  </CustomLink>
                  <Button bgColor="#555e8a" textColor="white" onClick={() => navigate("/login")}>
                    Login
                  </Button>
                  <Button className="flex-1 text-center text-[17px] whitespace-nowrap " bgColor="#1F2C56" textColor="#FFFFFF" onClick={() => navigate("/get-in-touch")}>
                    Contact us
                  </Button>
                  <a className="flex-1 text-center bg-light inline-block cursor-pointer py-2 px-8 leading-8 text-[18px] font-bold rounded-full text-[17px] whitespace-nowrap" href="https://neuralspacedesign.co.uk">
                    Insights
                  </a>
                </div>
              )}
       
          </div>
        </div>
      </header>
    </>
  );
};
