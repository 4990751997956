import React from "react";
import { Link as ReactLink } from "react-router-dom";

export const Link = ({
  to,
  onClick,
  className = "",
  children,
}) => {
  return (
    <ReactLink
      to={to}
      onClick={onClick}
      className={`inline-block cursor-pointer bg-yellow py-2 px-8 leading-8 text-[16px] font-bold rounded-full ${className}`}
    >
      {children}
    </ReactLink>
  );
};
