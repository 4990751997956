import React from "react";
import seo from "../lib/seo";
import { Footer } from "../components/commonSections/Footer";
import { Header } from "../components/commonSections/Header";
import { CookiePolicy as CookiePolicyComponent } from "../components/CookiePolicy";

export const CookiePolicy = () => {
  return (
    <>
      {seo({ title: 'Cookie Policy' })}
      <Header />
      <CookiePolicyComponent />
      <Footer />
    </>
  );
};
