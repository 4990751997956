import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/logo-white.svg";

export const Footer = () => {
  return (
    <footer className="bg-blue-dark pt-14 md:pt-16 pb-16 md:pb-24 text-light">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <Link
            to="/"
            onClick={() => {
              document.documentElement.scrollTop = 0;
              document.scrollingElement.scrollTop = 0;
            }}
          >
              <img
                className="w-[200px] md:w-[308px]"
                src={LogoIcon}
                alt="logo"
              />
          </Link>

          <div className="flex flex-col md:flex-row flex-wrap gap-6 md:gap-12 mt-12 md:mt-0 max-w-[240px] md:max-w-[100%]">
            <Link to="/get-in-touch">
              <div className="md:text-[1.175rem] hover:underline font-bold min-w-[100px] md:min-w-fit">
                Contact us
              </div>
            </Link>
            <Link to="/faqs">
              <div className="md:text-[1.175rem] hover:underline font-bold min-w-[100px] md:min-w-fit">
                FAQs
              </div>
            </Link>
            <Link to="/cookie-policy">
              <div className="md:text-[1.175rem] hover:underline font-bold min-w-[100px] md:min-w-fit">
                Cookie Policy
              </div>
            </Link>
            <Link to="/terms">
              <div className="md:text-[1.175rem] hover:underline font-bold min-w-[100px] md:min-w-fit">
                Terms
              </div>
            </Link>
            <Link to="/privacy">
              <div className="md:text-[1.175rem] hover:underline font-bold min-w-[100px] md:min-w-fit">
                Privacy
              </div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
