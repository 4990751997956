import React, { useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { SubmitButton } from "../components/SubmitButton";
import { TextInput } from "../components/TextInput";
import { Auth } from "aws-amplify";
import { Loader } from "./Loader";
import { ErrorMessage } from "./ErrorMessage";
import { getSession } from "../features/getSession";
import { useEffect } from "react";

async function signIn(
  {
    formEmail: username,
    formPassword: password,
  },
  questionnarie,
  setVisibleLoader,
  setLoggedIn,
  setError,
  navigate,
  location,
) {
  try {
    const user = await Auth.signIn(username, password);
    setVisibleLoader(false);
    setLoggedIn(true);

    if ( questionnarie ) {
      return navigate(`/planSubmitted`);
    }

    const { from } = location.state || { from: { pathname: "/" } };
    navigate(from, { replace: true });

  } catch (error) {
    setLoggedIn(false);
    setVisibleLoader(false);
    setError(error);
  }
}

export const SubStepEnum = {
  REGISTRATION: "registration",
};

export const LoginLayout = ({
  data,
  setStep,
  setData,
  onChangeStep = null,
  noPadding = false,
  questionnarie = false,
}) => {
  const location = useLocation();
  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [error, setError] = useState("");
  const [isVisibleLoader, setVisibleLoader] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      getSession()
        .then(async (res) => {
          setLoggedIn(res);
        })
        .catch((res) => {
          setLoggedIn(res);
        });
    }
  }, []);

  const onNextClick = (event) => {
    event.preventDefault();
    if (!formEmail || !formPassword) return;
    setVisibleLoader(true);
    signIn(
      {
        formEmail,
        formPassword,
      },
      questionnarie,
      setLoggedIn,
      setVisibleLoader,
      setError,
      navigate,
      location,
    );
  };

  const disabled = !formEmail || !formPassword;

  return (
    <>
      {isLoggedIn && questionnarie && <Navigate to="/planSubmitted" />}
      <div
        className={`text-light h-[80vh] ${
          noPadding ? "p-0" : "pt-[54px] pb-[15px]"
        }`}
      >
        <h2 className="font-bold text-blue-light md:text-[2rem] text-[1.875rem] mb-5 md:mb-3">
          Almost there
        </h2>
        <div className="m-0 mb-8 text-[1rem] md:text-[1.25rem]">
          Please enter your details to login
        </div>
        <form className="flex" onSubmit={onNextClick}>
          <div className="mb-5 w-full max-w-[345px]">
            <div className="mb-[10px]">
              <TextInput
                type="email"
                placeholder="Email"
                onChange={(event) => {
                  setFormEmail(event.target.value);
                }}
              />
            </div>
            <TextInput
              type="password"
              placeholder="Password"
              onChange={(event) => {
                setFormPassword(event.target.value);
              }}
            />
            <div className="mt-3 mb-6 hover:cursor-pointer">

              <div onClick={() => navigate('/forgot-password')}>
                <span className="underline text-[0.875rem]">
                  Forgotton your password?
                </span>
              </div>

              <div
                onClick={() => {
                  if (onChangeStep) {
                    return onChangeStep(SubStepEnum.REGISTRATION);
                  }
                  return navigate("/signup");
                }}
              >
                <span className="underline text-[0.875rem]">
                  I don’t have an account. Sign up here
                </span>
              </div>
            </div>
            <ErrorMessage error={error} />
            <SubmitButton
              disabled={disabled}
            />
          </div>
        </form>
        <Loader visible={isVisibleLoader} />
      </div>
    </>
  );
};
